.experience section {
    margin-top: 0;
    padding: 0;
    background-color: rgb(225, 225, 225);
}

.experience section p {
    margin: 0 auto;
    padding: 1rem;
    font-size: 1.2rem;
    color: #0f1626;
    text-align: left;
    width: 80%;
    background-color: rgb(236, 229, 216);

    box-shadow: 0 3px 0 #ddd;
    border-radius: 0.25em;
    max-width: 500px;

    transition: transform 0.1s ease-in-out;
}

.experience section p:hover {
    transform: scale(1.01);
}

.experience-subtitle {
    margin-top: 0;
    padding: 1rem 0;


    background-color: #ffffff;
    color: #0f1626;
    width: 100%;
    text-align: center;
}