.contact {
    width: 100%;
    height: calc(100vh - 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f5f5f5;
    /*21325e*/
}

form {
    width: 95%;
    margin: 1rem auto;

    display: flex;
    flex-flow: column;
    gap: 0.5rem;
}

.formElement {
    display: flex;
    flex-direction: column;
}

.formElement input {
    height: 2.5rem;
    background-color: #f5f5f5;
    border: 1px solid #e2e1e1;

    margin: 1rem 0;

}

.formElement input:focus,
textarea:focus {
    outline: #8aeda6 solid 1px;
}

.formElement label {
    text-align: left;
    font-size: 1.5rem;

}

textarea {
    margin: 1rem 0;

    height: 120px;
    resize: none;
    background-color: #f5f5f5;

    border: 1px solid #e2e1e1;
}