.card {
    width: 70%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 1000px) {
    .card {
        width: 50%;
    }
}