/* GOOGLE FONT */

.font-link {
  font-family: 'Fira Mono', monospace;
}

/*  -------- */

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Fira Mono', monospace;
  background-color: #f5f5f5;
}

body {
  padding: 0;
  margin: 0;
}

.active {
  color: rgb(232, 137, 99) !important;
}

.typewriter {
  text-align: center;
}