.footer {
    width: 100%;
    height: 200px;
    background: #0f1626;
    /* #21325e */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;

}

.socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
}

.footer p {
    color: white;
}

@media only screen and (max-width: 600px) {

    .footer {
        height: 140px;
    }

    .footer svg {
        font-size: 30px;
    }

    .footer p {
        font-size: .8rem;
        margin: 0 2rem;
    }
}