.navbar {
    position: sticky;
    top: 0;
    z-index: 9999;

    width: 100%;
    height: 100px;
    background: #361626;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F5F5F5;
}

.navbar a {
    color: #F5F5F5;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
}

.navbar a:hover {
    color: #f8cece;
}

.toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggleButton svg {
    font-size: 50px;
}

.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: #F5F5F5;
    cursor: pointer;
}

#open {
    height: 100vh;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
}

#open a {
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .navbar a {
        width: 120px;
        text-align: center;
    }

    .links {
        justify-content: space-between;
    }

    .links a:nth-child(3) {
        font-size: 23px;
    }
}

@media only screen and (max-width: 600px) {
    .toggleButton {
        display: flex;
    }

    #close a {
        display: none;
    }

    .links {
        height: 0;
    }

    .intro {
        font-size: 13px;
    }

}

@media only screen and (min-width: 600px) {
    .toggleButton {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
    }
}