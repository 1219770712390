.project {
    width: 100%;
    /* height: calc(100vh - 100px); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project h1,
.projects h1:first-child {
    margin-top: 50px;
    color: #3e497a;
    font-size: 35px;
    text-align: center;
}

.cancel-icon {
    /* z-index: 9999; */
}

.project img {
    height: auto;
    width: 700px;
    max-width: 95%;
    border-radius: 10px;

    transition: transform 0.2s;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
}

.project img:hover {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    transform: scale(1.01);
}

.project-info-wrapper {
    max-width: 95%;
    align-self: center;
    margin: 4rem;
    padding: 0;
}

.project-info-wrapper li {
    margin: auto;
    list-style: none;
}

.project-info {
    font-size: 1.5rem;
    word-wrap: break-word;
}

.project svg {
    margin: 0.5rem;
    font-size: 60px;
    color: #3e497a;
}


@media only screen and (max-width: 540px) {
    .project-info {
        font-size: 1rem;
        word-wrap: break-word;
    }

    .project-info-wrapper {
        margin: 1rem auto;
        max-width: 95%;
    }
}