/* 

.angry-grid {
   display: grid; 

   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   
   gap: 0px;
   height: 100%;
   
}
  
#item-0 {

   background-color: #89fcfb; 
   grid-row-start: 1;
   grid-column-start: 1;

   grid-row-end: 2;
   grid-column-end: 2;
   
}
#item-1 {

   background-color: #be8fbd; 
   grid-row-start: 2;
   grid-column-start: 1;

   grid-row-end: 3;
   grid-column-end: 2;
   
}
#item-2 {

   background-color: #abbb9d; 
   grid-row-start: 1;
   grid-column-start: 2;

   grid-row-end: 3;
   grid-column-end: 3;
   
}
*/


.home {
    width: 100%;
    align-items: center;
    font-family: 'Fira Mono', monospace;
}

.about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #3F3351;
    /*21325e*/
    color: #F5F5F5;
}

.about h2 {
    font-size: 80px;
    color: #f5f5f5;
    margin-bottom: 0;
    /* height: 50px; */
}

.home-subtitle {
    font-size: 1.5rem;
}

.intro {
    font-size: 1rem;
}

.about .prompt {
    width: 40%;
    font-size: 30px;
}

.prompt svg {
    font-size: 60px;
    margin: 5px;
    color: #f5f5f5;
}

.skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    background-color: #3F3351;
}

.skills h2 {

    font-size: 2rem;
    background-color: #361626;
    color: #f5f5f5;
    width: 100%;
    height: auto;
    text-align: center;

    padding: 15px 0;
    margin: 0 0 1rem 0;
}

.skills h3 {
    color: #f5f5f5;
    font-size: 1.6rem;
    margin-top: 4rem;
}

.skills p {
    font-size: 1.5rem;
}

.skills .list {
    list-style: none;
    width: 60%;
}

.skills img {
    max-width: 95%;
    border-radius: 8px;
}

.list span {
    font-size: 20px;
}

.item {
    max-width: 450px;
    margin: 1rem auto;
    padding: 1rem;

    background-color: #594d6a;

    color: #f5f5f5;

    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 1200px) {
    .about h2 {
        font-size: 50px;
    }

    .intro {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 850px) {
    .home-subtitle {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 660px) {
    .about h2 {
        font-size: 40px;
    }

    .about .prompt {
        margin-top: 10px;
        font-size: 20px;
        width: 80%;
    }

    .home-subtitle {
        font-size: 1rem;
    }

    .intro {
        font-size: 1rem;
    }

    .skills {
        text-align: center;
    }

    .list {
        padding: 0;
    }

    .skills h2 {
        font-size: 18px;
        padding: 1rem 0;
        margin: 0 0 5rem 0;
    }


    .item h3 {
        font-size: 1.5rem;
    }
}